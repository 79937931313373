import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faQuoteRight,
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import BackToTopButton from '../../components/BackToTopButton'
import Link from '../../components/LinkWrapper'
import i18n from '../../locales/he.yaml'

const LiveShowFigureQuery = graphql`
  query LiveShowFigureQuery {
    daffy: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-מעוצבת-בבצק-סוכר-ודאפי-דאק-מפוסל-בבצק-סוכר.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    robot: file(relativePath: { eq: "רובוטית-מפוסלת-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    caterpillar: file(relativePath: { eq: "הזחל-המעשן-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    speedy: file(relativePath: { eq: "ספידי-גונזלס-מפוסל-בבצק-סוכר.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const LiveShowFigureTemplate = ({ pathname, search, data }) => {
  const { daffy, robot, caterpillar, speedy, survival } = data
  const [iframeUrl, setIframeUrl] = useState(null)
  useEffect(() => {
    setIframeUrl(
      `https://www.karinnarts.co.il/forms/?ff_landing=27&embedded=1` +
        (search
          ? '&' +
            search
              .slice(1)
              .split('&')
              .filter(
                (item) =>
                  item.split('=')[0] !== 'ns_url' &&
                  item.split('=')[0] !== 'mid'
              )
              .join('&')
          : '')
    )
  }, [])

  const BULLETS_IMAGE = [daffy, speedy, robot, caterpillar]

  return (
    <>
      <BackToTopButton selectorId='live-show2' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {i18n.header.liveShowFigure}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column'>
                <h2 className='is-size-4 has-text-nude'>
                  {i18n.liveShowFigure.subtitle}
                </h2>
                <h1 className='is-size-4'>{i18n.liveShowFigure.title}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {i18n.liveShowFigure.bullets.map((bullet, index) => (
        <div
          id={index === 1 ? 'live-show2' : undefined}
          key={index}
          className={clsx('hero', {})}
        >
          <div className='hero-body'>
            <div className='container'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <GatsbyImage
                    image={getImage(BULLETS_IMAGE[index])}
                    title={bullet.image.title}
                    alt={bullet.image.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(BULLETS_IMAGE[index]).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {bullet.image.title}
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    'column is-full-mobile is-10-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 0
                    }
                  )}
                >
                  <div className='content'>
                    <h3>{bullet.title}</h3>
                    <p>{bullet.description}</p>
                    <strong>{bullet.question}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <FontAwesomeIcon
              icon={faCalendarDays}
              className='has-text-nude is-margin-bottom-4'
              size='lg'
            />
            <div className='is-margin-bottom-4'>
              <h3>{i18n.liveShowFigure.when.title}</h3>
            </div>
            <p>{i18n.liveShowFigure.when.description}</p>
            <p className='is-italic'>{i18n.liveShowFigure.when.hint}</p>
          </div>
        </div>
      </div>
      {iframeUrl && (
        <div id='registration' className='hero'>
          <div className='hero-body is-padding-bottom-0'>
            <div className='container content has-text-centered'>
              <h3>{i18n.liveShowFigure.registration.title}</h3>
              <p>{i18n.liveShowFigure.registration.hint}</p>
              <div className='columns is-centered'>
                <div className='column is-full-mobile is-10-tablet is-6-desktop'>
                  <iframe
                    id='fluentform'
                    width='100%'
                    height='100%'
                    loading='lazy'
                    style={{
                      minHeight: '400px',
                      width: '100%'
                    }}
                    frameBorder='0'
                    src={iframeUrl}
                    onLoad={(event) => {
                      event.target.style.height =
                        event.target.contentWindow.document.body.scrollHeight +
                        'px'
                    }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='has-text-centered is-margin-bottom-6'>
              <h3>{i18n.liveShowFigure.testimonials.title}</h3>
            </div>
            {i18n.liveShowFigure.testimonials.testimonials.map(
              (testimony, index) => (
                <div key={testimony.title} className='columns is-centered'>
                  <div className='column is-6-widescreen is-8-desktop is-10-tablet is-12-mobile'>
                    <div
                      className={clsx(
                        'columns is-flex-direction-column is-mobile is-align-items-center',
                        { 'direction-column-reverse': testimony.isEnglish }
                      )}
                    >
                      <div className='column is-align-self-flex-start'>
                        <FontAwesomeIcon icon={faQuoteRight} size='lg' />
                      </div>
                      <div
                        className='column is-padding-top-0 is-padding-bottom-0'
                        dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                      >
                        <h4 className='has-text-gold-black is-margin-bottom-0'>
                          {testimony.title}
                        </h4>
                      </div>
                      <div className='column is-align-self-flex-end'>
                        <FontAwesomeIcon icon={faQuoteLeft} size='lg' />
                      </div>
                    </div>
                    <div
                      className={clsx('columns is-align-items-start', {
                        'direction-row-reverse': testimony.isEnglish
                      })}
                    >
                      <div className='column is-narrow'>
                        <p className='has-text-centered has-text-weight-bold'>
                          {testimony.name}
                        </p>
                      </div>
                      <div className='column'>
                        <p
                          className='is-margin-bottom-0'
                          dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                        >
                          {testimony.text}
                        </p>
                      </div>
                    </div>
                    {index <
                      i18n.liveShowFigure.testimonials.testimonials.length -
                        1 && <hr />}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className='hero has-background-nude-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered'>
              <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                <h3>{i18n.liveShowFigure.about.title}</h3>
                <p>{i18n.liveShowFigure.about.description}</p>
              </div>
              <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen is-order-3-touch'>
                <GatsbyImage
                  image={getImage(survival)}
                  title={i18n.liveShowFigure.about.mainImage.title}
                  alt={i18n.liveShowFigure.about.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(survival).width,
                    margin: '0 auto' // used to center the image
                  }}
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.liveShowFigure.about.mainImage.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const LiveShowFigure = ({ location: { pathname, search } }) => {
  const data = useStaticQuery(LiveShowFigureQuery)
  return (
    <Layout>
      <Seo
        metadata={i18n.liveShowFigure.metadata}
        pathname={pathname}
        robots='noindex, nofollow'
        og={{
          image: getSrc(data.caterpillar),
          alt: i18n.liveShowFigure.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            {
              name: i18n.header.liveShowFigure,
              path: '/academy/live-show-figure/'
            }
          ]
        }}
      />
      <LiveShowFigureTemplate pathname={pathname} search={search} data={data} />
    </Layout>
  )
}

export default LiveShowFigure
